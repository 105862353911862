<template>
	<div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
		<div class="flex flex-col h-full overflow-y-auto col-span-1 md:col-span-2">
			<div class="flex-1 pt-10 sm:pt-20 px-3 sm:px-0 ">
				<div class="max-w-md px-2 mx-auto">
					<img src="../../../assets/images/dscvry_logo-purple.png" class="h-6"/>
					<Text content="Great! Now let's setup a payment method" customClass="mt-8 tracking-tight" weight="medium" size="2xl" color="gray-800"/>
				</div>
				<div class="flex flex-col mt-4">
					<div class="max-w-md px-2 mx-auto space-y-6 w-full flex flex-col">
						<div class="grid grid-cols-2 gap-x-3 gap-y-4  rounded-md">
							<Input v-for="key in Object.keys(inputs)" :key="key"
										 v-model:value="inputs[key].value" :rounded="inputs[key].rounded" :type="inputs[key].type"
										 :customClass="inputs[key].customClass" :label="inputs[key].label"
										 :input-mask="inputs[key].mask"
                     :is-error="inputs[key].error"
                     :help-text="inputs[key].error"
										 :placeholder="inputs[key].placeholder" :class="`col-span-${inputs[key].cols}`"/>
							<div class="col-span-2">
								<Text size="sm" weight="semibold" color="gray-700" content="You will be charged $199 at the start of each month."/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-none w-full border-t">
				<div class="max-w-md mx-auto  px-5 sm:px-3">
					<div class="flex justify-end items-center space-x-8 py-3 sm:py-6">
						<Text size="sm" weight="semibold" decoration="underline" content="Back" color="gray-700" custom-class="cursor-pointer"/>
						<Button :content="'Next'" variant="primary" :onClick="() => $emit('onSubmit')"
										:is-loading="isLoading"/>
					</div>
				</div>

			</div>
		</div>
		<div class="hidden h-full col-span-1 md:col-span-3 md:block">
			<img src="../../../assets/images/login_page_sidepanel.png" :alt="image.alt" class="h-screen w-full object-cover"/>
		</div>
	</div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Input from '../../molecules/Inputs/Component/Component.vue';
import Select from '../../molecules/Selects/Component/Component.vue';
import Button from '../../atoms/Button/Button.vue';
import Icon from '../../atoms/Icons/Icons.vue';
import PasswordInput from '../../molecules/Inputs/PasswordInput/PasswordInput.vue'
import FileUploader
	from '../../molecules/FilePickers/SimpleWithEmptyStateAndImagePreview/SimpleWithEmptyStateAndImagePreview'

export default {
	components: {
		Text,
		Link,
		Checkbox,
		Input,
		Button,
		Icon,
		PasswordInput,
		Select,
		FileUploader
	},
	props: {
		image: {
			type: Object,
			default: () => {
			}
		},
		logo: {
			type: Object,
			default: () => {
			}
		},
		inputs: {
			type: Object,
			default: () => {
			}
		},
		button: {
			type: Object,
			default: () => {
			}
		},
		footerTitle: {
			type: String,
			default: ''
		},
		icons: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isValid: false
		}
	},
};
</script>
