<template>
	<div class="space-y-3 flex flex-col">
		<label v-if="!selectedFile && !image" for="upload"
			class="w-40 h-40 bg-gray-50 border border-dashed border-gray-300 flex flex-col items-center justify-center">
			<Icon :name="emptyIcon" isOutline size="5" color="gray-600" />
			<Text size="base" color="gray-600" weight="medium" :content="emptyText" class="mt-1" />
		</label>
		<input id="upload" :name="name" class="outline-none hidden" type="file" @change="onFileChange" :accept="fileTypes ? fileTypes : '*'" />
		<img v-if="image" :src="image" class="w-40 h-40 object-contain bg-gray-100" />
		<Text v-else :content="selectedFile && selectedFile.name" />
		<div v-if="selectedFile" class="flex items-center space-x-2">
			<label for="upload" class="underline text-primary-500">{{ editText }}</label>
			<Text color="rose-500" customClass="underline cursor-pointer" content="Remove" @click="onRemove" />
		</div>
	</div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Link from '../../../atoms/Link/Link.vue'

export default {
	components: {
		Icon,
		Text,
		Link
	},
	props: {
		/**
		 * empty state text
		 */
		emptyText: {
			type: String,
			default: ''
		},
		/**
		 * empty state icon
		 */
		emptyIcon: {
			type: String,
			default: 'CloudUploadIcon'
		},
		/**
		 * edit button text
		 */
		editText: {
			type: String,
			default: ''
		},
		/**
		 * selected file
		 */
		file: {
			type: Object,
			default: () => { }
		},
		logo: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: 'file'
		},
		fileTypes: {
			type: String,
			default: ''
		}
	},
	methods: {
		onFileChange: async function (ev) {
			const files = ev.target.files;
			console.log(files, 'files');
			if (files.length > 0) {
				this.selectedFile = files[0];
				this.$emit('update:file', files[0])
				if (files[0].type?.includes('image')) {
					await this.createImage(files[0]);
				} else {
					this.image = null;
				}
			}

		},
		createImage: async function (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				this.image = e.target.result;
				this.$emit('updateLogo', this.image)
			};
			reader.readAsDataURL(file);
		},
		async onRemove() {
			this.image = null;
			this.selectedFile = null;
			this.$emit('update:file', null);
			this.$emit('updateLogo', null)
		}
	},
	data() {
		return {
			selectedFile: null,
			image: this.logo,
		}
	}
}

</script>
