<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
      @onSubmit="onSave"
	/>
</template>
<script>
import Template from '../components/templates/SetupPayment/SetupPayment';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
	  async onSave() {
      if (this.isLoading) return false;
      if (!this.params.inputs.name?.value) {
        this.params.inputs.name.error = 'Please type card name';
        return false;
      }
      if (!this.params.inputs.number?.value) {
        this.params.inputs.number.error = 'Please type card number';
        return false;
      }
      if (!this.params.inputs.expiration.value) {
        this.params.inputs.expiration.error = 'Please type expire date';
        return false;
      }
      if (!this.params.inputs.cvv.value) {
        this.params.inputs.cvv.error = 'Please type ccv';
        return false;
      }
      const expireMonth = this.params.inputs.expiration.value?.split('/')[0]
      const expireYear = this.params.inputs.expiration.value?.split('/')[1];
      const firstName = this.params.inputs.name?.value?.split(' ')[0]
      const lastName = this.params.inputs.name?.value?.split(' ')[1]
      const params = {
        paymentMethod: {
          firstName: firstName,
          lastName: lastName,
          verificationCode: this.params.inputs.cvv.value,
          month: expireMonth,
          year: expireYear,
          number: this.params.inputs.number.value.replace(/[^A-Z0-9]/ig, ''),
          methodType: 'credit_card',
          type: 'payment',
          // billingZip: this.zipCode,
        },
      };
      try {
        this.isLoading = true;
        const user = await this.actions.user.updateUserProfile(params);
        if (user?.id) {
          this.actions.alert.showSuccess({message: 'Upgraded payment method successfully'});
          this.$router.push('/invite-people')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    }
	},
	data() {
		return {
			params: c.setup_payment,
			isLoading: false,
		}
	},
}
</script>
