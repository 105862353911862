<template>
  <a 
    :href="url"
    :key="key"
    :aria-current="ariaCurreent"
    :class="`text-${size} text-${color} text-${align} ${transform} ${decoration} font-${weight}  hover:text-${hoverColor} hover:bg-${hoverBg} focus:outline-none focus:text-${focusColor} focus:bg-${focusBg} ${customClass}`"
  >
    <slot v-if="leading" />
    {{ content }}
  </a>
</template>

<script>
/**
- Use for links with text
- options to customize size,color, content,alignment,transformation, decoration
**/

export default {
  name: "Link",
  props: {
    /**
      sets url of link. Default is #
      */
    url: {
      type: String,
      default: "#",
    },
    key: {
      type: String,
      default: "",
    },
    ariaCurreent: {
      type: String,
      default: "",
    },
    /**
      sets size of link text from xs to 9xl. Default is base
      */
    size: {
      type: String,
      default: "",
    },
    /**
      sets color of link text.
      */
    color: {
      type: String,
      default: "",
    },
    /**
      sets content of link.
      */
    content: {
      type: String,
      default: "",
    },
    /**
      sets alignment of link text i.e. left,right,center
      */
    align: {
      type: String,
      default: "",
    },
    /**
      sets tranformation options of link text i.e. uppercase, lowercase etc.
      */
    transform: {
      type: String,
      default: "",
    },
    /**
      sets decoration options of link text i.e. underline, strikethrough etc.
      */
    decoration: {
      type: String,
      default: "",
    },
    /**
      used to add additional classes to link text like letter spacing
      */
    customClass: {
      type: String,
      default: "",
    },
    /**
      sets font weight.
      */
    weight: {
      type: String,
      default: "medium",
    },
    /**
      sets hover color of link text.
      */
    hoverColor: {
      type: String,
      default: "",
    },
    /**
      sets focus color of link text.
      */
    focusColor: {
      type: String,
      default: "",
    },
    /**
      sets hover background color of link text.
      */
    hoverBg: {
      type: String,
      default: "",
    },
    /**
      sets focus background color of link text.
      */
    focusBg: {
      type: String,
      default: "",
    },
    /**
     * to show content in leading slot
     */
    leading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
